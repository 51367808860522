.left_nav.collapse .bigger_icon_when_collapse .icon_container {
	margin-left: auto;
	margin-right: auto;
}

.left_nav.collapse .bigger_icon_when_collapse svg {
	transition: transform 75ms;
	transform: scale(1);
	width: 20px !important;
	height: 20px !important;
}

.left_nav.collapse .bigger_icon_when_collapse:hover svg {
	transform: scale(1.1);
}
