.div {
   position: absolute;
   top: 0;
   height: 100%;
   width: 100%;
   left: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   z-index: 1;
}
