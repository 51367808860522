.link,
.item,
.button {
	@apply w-full 
		px-2
		flex
		items-center 
		rounded-sm
		text-default
		whitespace-nowrap;
	min-height: 32px;
}

.link,
.button {
	@apply transition 
		duration-75
		hover:bg-gray-150;
}
