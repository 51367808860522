@layer components {
	/* for react-custom-scrollbar */
	.scrollbars-bigger > div:nth-child(2) {
		height: 9px !important;
		border-radius: 4px !important;
		z-index: 10000;
	}
	.scrollbars-bigger > div:nth-child(3) {
		width: 9px !important;
		border-radius: 4px !important;
		z-index: 10000;
	}

	/* put this class on an element */
	.custom-scrollbar::-webkit-scrollbar-track {
		border-radius: 0px;
		background-color: white;
	}

	.custom-scrollbar::-webkit-scrollbar {
		width: 12px;
		height: 12px;
		background-color: white;
	}

	.custom-scrollbar::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
		background-color: #d3d8da;
		cursor: pointer;
	}

	.custom-scrollbar:hover {
		background-color: #adb9be;
		cursor: pointer;
	}
}
