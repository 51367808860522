/**
 * BASE
 */
@layer components {
	.button {
		appearance: none;
		user-select: none;
		line-height: 1.5;
		cursor: pointer;

		transition: var(--transition, all 100ms);
		opacity: var(--opacity, 1);
		filter: var(--filter, unset);
		border: var(--border, none);
		border-radius: var(--border-radius, 3px);
		background: var(--background, transparent);
		color: var(--color, var(--clr-default));
		font-size: var(--font-size, inherit);
		font-weight: var(--font-weight, inherit);
		margin: var(--margin, 0);
		padding: var(--padding, 0);
		display: var(--display, inline-flex);
		align-items: var(--align-items, center);
		justify-content: var(--justify-content, center);
		box-shadow: var(--box-shadow, none);
		gap: var(--gap, 0.45em);
		transform: var(--transform, unset);
		text-transform: var(--text-transform, unset);
		font-family: var(--font-family, inherit);
		pointer-events: var(--pointer-events, unset);
		text-decoration: var(--text-decoration, underline transparent solid);
		background-image: var(
			--background-image,
			linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 100%)
		);
		--icon-size: unset;
	}

	.button:hover,
	.button.hover {
		--color: var(--clr-primary-darker);
	}

	.button.bp3-icon {
		@apply flex-center;
	}

	.button .bp3-icon svg {
		fill: currentColor;
		overflow: visible;
	}

	.button svg {
		height: var(--icon-size);
		width: var(--icon-size);
		overflow: visible;
	}
}

/**
 * VARIANTS
 */

/* CONTAINED */
@layer components {
	.button.contained {
		--box-shadow: var(--shadow-low);
		--padding: 0.25em 0.875em;
		--font-weight: 599;
		--color: #5c6069;
		--background: var(--clr-light1);
	}

	.button.contained.hover:not(:active),
	.button.contained:hover:not(:active) {
		--box-shadow: var(--shadow-low-hover);
		--background: white;
		--filter: brightness(1.07);
	}

	.button.contained.round {
		--padding: 0.375em 1em;
	}

	/* INTENTS */
	.button.contained.intent_danger,
	.button.contained.intent_danger.hover,
	.button.contained.intent_danger:hover {
		--background: var(--clr-danger);
		--color: var(--clr-light1);
	}
	.button.contained.intent_warning,
	.button.contained.intent_warning.hover,
	.button.contained.intent_warning:hover {
		--background: var(--clr-warning);
		--color: var(--clr-light1);
	}
	.button.contained.intent_success,
	.button.contained.intent_success.hover,
	.button.contained.intent_success:hover {
		--background: var(--clr-success);
		--color: var(--clr-light1);
	}
	.button.contained.intent_primary,
	.button.contained.intent_primary.hover,
	.button.contained.intent_primary:hover {
		--background: var(--clr-primary);
		--color: var(--clr-light1);
	}
}

/* ICON */
@layer components {
	.button.icon {
		--background: var(--clr-light1);
		--color: #5c6069;
		--padding: 0.375rem;
		--box-shadow: var(--shadow-low);
	}
	.button.icon.hover:not(:active),
	.button.icon:hover:not(:active) {
		--box-shadow: 0 0 0 0 transparent;
		--box-shadow: var(--shadow-low-hover);
		--background: white;
		--filter: brightness(1.07);
	}
	.button.icon:active {
		--box-shadow: inset 0 1px 3px #00000040;
	}

	.button.icon.round {
		--padding: 9px;
	}

	/* INTENTS */
	.button.icon.intent_danger,
	.button.icon.intent_danger.hover,
	.button.icon.intent_danger:hover {
		--background: var(--clr-danger);
		--color: var(--clr-light1);
	}
	.button.icon.intent_warning,
	.button.icon.intent_warning.hover,
	.button.icon.intent_warning:hover {
		--background: var(--clr-warning);
		--color: var(--clr-light1);
	}
	.button.icon.intent_success,
	.button.icon.intent_success.hover,
	.button.icon.intent_success:hover {
		--background: var(--clr-success);
		--color: var(--clr-light1);
	}
	.button.icon.intent_primary,
	.button.icon.intent_primary.hover,
	.button.icon.intent_primary:hover {
		--background: var(--clr-primary-darker);
		--color: var(--clr-light1);
	}
}

/* UPPER TEXT */
@layer components {
	.button.upper_text {
		--text-transform: uppercase;
		--font-weight: 599;
		--color: #969eaa;
		--padding: 0.125rem 0.5rem;
		--transition: all 0ms;
	}
	.button.upper_text.hover,
	.button.upper_text:hover {
		--background: var(--color);
		--background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.75) 100%);
	}
	.button.upper_text:active {
		--box-shadow: inset 0 1px 3px #00000030;
	}

	/* INTENTS */
	.button.upper_text.intent_danger {
		--color: var(--clr-danger);
	}
	.button.upper_text.intent_warning {
		--color: var(--clr-warning);
	}
	.button.upper_text.intent_success {
		--color: var(--clr-success);
	}
	.button.upper_text.intent_primary {
		--color: var(--clr-primary-darker);
	}
}

/* DISC */
@layer components {
	.button.disc {
		--padding: 9px;
		--transition: all 0ms;
	}
	.button.disc.light {
		--color: var(--clr-light1);
	}
	.button.disc.light.hover,
	.button.disc.light:hover {
		--color: var(--clr-primary-lighter);
	}
	.button.disc.hover,
	.button.disc:hover {
		--padding: 9px;
		--background: var(--clr-primary-hover);
		--border-radius: 9999px;
		--color: var(--clr-primary-darker);
	}
	.button.disc:active {
		--opacity: 0.85;
	}

	/* INTENTS */
	.button.disc.intent_danger:hover,
	.button.disc.intent_danger.hover,
	.button.disc.intent_warning:hover,
	.button.disc.intent_warning.hover,
	.button.disc.intent_success:hover,
	.button.disc.intent_success.hover,
	.button.disc.intent_primary:hover,
	.button.disc.intent_primary.hover {
		--background: var(--color);
		--background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.75) 100%);
	}
	.button.disc.intent_danger {
		--color: var(--clr-danger);
	}
	.button.disc.intent_warning {
		--color: var(--clr-warning);
	}
	.button.disc.intent_success {
		--color: var(--clr-success);
	}
	.button.disc.intent_primary {
		--color: var(--clr-primary-darker);
	}
}

/* INLINE TEXT */
@layer components {
	.button.inline_text.hover,
	.button.inline_text:hover {
		--text-decoration: underline;
	}

	/* INTENTS */
	.button.inline_text.intent_danger,
	.button.inline_text.intent_danger.hover,
	.button.inline_text.intent_danger:hover {
		--color: var(--clr-danger);
	}
	.button.inline_text.intent_warning,
	.button.inline_text.intent_warning.hover,
	.button.inline_text.intent_warning:hover {
		--color: var(--clr-warning);
	}
	.button.inline_text.intent_success,
	.button.inline_text.intent_success.hover,
	.button.inline_text.intent_success:hover {
		--color: var(--clr-success);
	}
	.button.inline_text.intent_primary,
	.button.inline_text.intent_primary.hover,
	.button.inline_text.intent_primary:hover {
		--color: var(--clr-primary-darker);
	}
}

/**
 * MODIFIERS
 */
@layer components {
	/* ROUND */
	.button.round {
		--border-radius: 9999px;
	}

	/* DISABLED */
	.button.disabled {
		--box-shadow: none;
		--opacity: 0.5;
		--pointer-events: none;
	}
	.button.disabled.contained,
	.button.disabled.icon {
		--background: var(--clr-disabled);
		--color: white;
	}
	.button.disabled.disc,
	.button.disabled.upper_text,
	.button.disabled.inline_text {
		--color: var(--clr-disabled);
	}
}
