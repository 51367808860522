@layer utilities {
	.grow {
		flex-grow: 100;
	}

	.shrink {
		flex-shrink: 1;
	}

	.no-shrink {
		flex-shrink: 0 !important;
	}

	.fill {
		width: 0;
		flex-grow: 1;
	}
}
