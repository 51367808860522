@layer components {
	.app_toast-container {
		width: 300px;
		bottom: 0;
	}

	.app_toast {
		@apply p-0 text-sm flex-col font-bolder;
		line-height: 1.5;
		overflow: hidden;
	}

	.app_toast-body {
		@apply m-0 px-3 pb-1.5 pt-2 flex items-center;
	}

	.app_toast-progress {
		@apply static;
	}
}
