:root {
	/**
	* WARNING
	* ANY CHANGE TO THE COLORS SHOULD BE MIRRORED IN:
	* tailwind.config.js
	*/

	/**
	 * HEX ONLY
	 */
	--clr-primary: #3cabc3;
	--clr-primary-lighter: #63cae1;
	--clr-primary-darker: #009cbe;
	--clr-primary-xdarker: #2389a0;
	--clr-primary-hover: #63cae130;
	--clr-error: #ff0000;
	--clr-info: #86898f;
	--clr-progress: #dfb520;
	--clr-warning: #ffa545;
	--clr-success: #5fb71a;
	--clr-danger: #ce4449;
	--clr-disabled: #888888;
	--clr-default: #434952;
	--clr-dark1: #363e49;
	--clr-light1: #fcfcfc;
	--clr-light2: #f8f8f8;
	--clr-light3: #f5f5f5;
	--clr-light4: #f0f0f0;
	--clr-orange: #ffa500;
	--clr-gray-150: #eeeeee;
	--clr-border: #d6d9de;
	--clr-border-dark: #d1d5db;
}
