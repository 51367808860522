@layer utilities {
	.grabbable {
		cursor: url('../../assets/cursor/pointer_grab.png') 10 10, grab !important;
	}

	.grabbable:active {
		cursor: grabbing !important;
	}

	.cursor-crosshair {
		cursor: crosshair;
	}
}
