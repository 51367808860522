@layer components {
	.custom_text_element {
		transition: color var(--animation-duration), opacity var(--animation-duration);
		white-space: pre-wrap;
		text-decoration: inherit;
	}

	h1.custom_text_element,
	h2.custom_text_element,
	h3.custom_text_element,
	h4.custom_text_element {
		line-height: initial;
	}
}
