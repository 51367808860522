.nav {
	height: 50px;
	width: 100%;
	background: var(--clr-dark1);
	color: var(--clr-light1);
	padding: 0 var(--padding-app-x);
	display: flex;
	flex-shrink: 0;
}

.ul {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
}

.ul li,
.ul a {
	display: flex;
}

.ul a,
.left button {
	color: inherit;
	font-size: 17px;
	padding: 0 16px 1px 16px;
	display: flex;
	align-items: center;
}

.ul :global a:hover:not(:active):not(.active),
.left :global button:hover:not(:active):not(.active) {
	opacity: 0.85;
}

.ul :global a:hover,
.ul :global a.active,
.left :global button:hover,
.left :global button.active {
	color: var(--clr-primary-lighter);
	text-decoration: none;
	text-decoration-color: transparent;
}

.logo {
	display: flex;
	width: 32px;
	background-image: url('../../assets/logo.min.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 32px;
	margin-left: -1px;
}

.right {
	margin-left: auto;
	margin-right: -8px;
}

.right li {
	align-items: center;
	margin-left: 12px;
}
