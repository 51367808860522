#hotTable .tbBold {
	font-weight: 700;
}

#hotTable .tbItalic {
	font-style: italic;
}

#hotTable > .ht_master > .wtHolder:hover {
	background-color: transparent !important;
}

#hotTable > .ht_master > .wtHolder::-webkit-scrollbar-track {
	border-radius: 0px;
	background-color: #f5f5f5;
	cursor: pointer;
	/* border-top: 1px solid #d0d0d0;
	border-left: 1px solid #d0d0d0; */
}

#hotTable > .ht_master > .wtHolder::-webkit-scrollbar {
	width: 13px;
	height: 13px;
	background-color: #f5f5f5;
	cursor: pointer;
}

#hotTable > .ht_master > .wtHolder::-webkit-scrollbar-thumb {
	border-radius: 6px;
	/* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2); */
	background-color: rgba(0, 0, 0, 0.2);
	cursor: pointer;
	border: 2px solid #f5f5f5;
}

#hotTable > .ht_master > .wtHolder:hover {
	background-color: #adb9be;
	cursor: pointer;
}
