@tailwind base;

@layer base {
	blockquote,
	dl,
	dd,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	hr,
	figure,
	p,
	pre {
		margin: 0;
		font-weight: normal;
	}

	[class*='text-'] {
		transition: color var(--animation-duration), opacity var(--animation-duration);
		white-space: pre-wrap;
	}

	a {
		@apply text-primary-darker;
		cursor: pointer;
		font-family: inherit;
		text-decoration: none;
		text-decoration-color: transparent;
		transition: opacity var(--animation-duration), color var(--animation-duration);
	}

	button {
		font-family: inherit;
		font-weight: inherit;
		cursor: pointer;
	}

	html,
	body,
	#root {
		height: 100%;
		padding: 0;
		overflow: hidden;
		font-size: 16px;
		line-height: 1.5;
		@apply text-default;
		font-family: 'Open Sans', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI';
	}

	body {
		margin: 0;
		font-family: 'Open Sans', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI';
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		@apply bg-light3;
	}

	pre {
		font-family: inherit;
		margin: 0;
		display: inline-block;
	}

	h1 {
		font-size: 2rem;
		font-weight: 599;
	}

	h2 {
		font-size: 1.5rem;
		font-weight: 599;
	}

	h3 {
		font-size: 1.25rem;
		font-weight: 599;
	}

	h4 {
		font-weight: 599;
	}

	strong {
		font-weight: 599;
	}

	small {
		font-size: 0.875rem;
	}

	em {
		color: red;
		font-style: normal;
	}

	td {
		padding: 0;
	}

	a:focus,
	button:focus {
		outline: none;
	}

	a:focus-visible,
	button:focus-visible {
		outline: rgba(19, 124, 189, 0.6) auto 2px;
		outline-offset: 0px;
	}

	*.disabled,
	*:disabled {
		filter: grayscale(1);
		pointer-events: none;
		opacity: 0.5;
		cursor: default;
	}

	[class^='input'] {
		color: inherit;
	}

	.grab {
		cursor: url('../../assets/cursor/pointer_grab.png') 10 10, grab !important;
	}

	.grab:active {
		cursor: grabbing !important;
	}

	.konvajs-content.hover-rect {
		cursor: pointer;
	}
	.custom__color__picker {
		box-shadow: none !important;
	}
}
