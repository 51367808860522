/* TYPE = EMAIL | NUMBER | PASSWORD | SEARCH | TEXT */
@layer components {
	.custom_input-email,
	.custom_input-number,
	.custom_input-password,
	.custom_input-search,
	.custom_input-text {
		--line-height: 2em;
		--padding-top: 0;
		--padding-bottom: 0;
		--border-radius: 4px;
		--border: 1px solid var(--clr-border-dark);
		/* --box-shadow: var(--shadow-inner); */
		--padding-left: 0.625em;
		--padding-right: 0.625em;
		--width: unset;
		--text-align: unset;
		line-height: var(--line-height);
		padding-top: var(--padding-top);
		padding-bottom: var(--padding-bottom);
		border-radius: var(--border-radius);
		border: var(--border);
		/* box-shadow: var(--box-shadow); */
		padding-left: var(--padding-left);
		padding-right: var(--padding-right);
		width: var(--width);
		text-align: var(--text-align);
	}

	.custom_input-email:focus,
	.custom_input-number:focus,
	.custom_input-password:focus,
	.custom_input-search:focus,
	.custom_input-text:focus {
		box-shadow: 0 0 0 1px var(--clr-primary);
		border: 1px solid var(--clr-primary);
	}

	/* MODIFIERS */
	.custom_input-email.round,
	.custom_input-number.round,
	.custom_input-password.round,
	.custom_input-search.round,
	.custom_input-text.round {
		--border-radius: 9999px;
		--padding-left: 0.75em;
	}

	.custom_input-text.ch-1,
	.custom_input-number.ch-1 {
		--width: calc(1ch + 2 * var(--padding-right) + 2px);
	}
	.custom_input-text.ch-2,
	.custom_input-number.ch-2 {
		--width: calc(2ch + 2 * var(--padding-right) + 2px);
	}
	.custom_input-text.ch-3,
	.custom_input-number.ch-3 {
		--width: calc(3ch + 2 * var(--padding-right) + 2px);
	}
	.custom_input-text.ch-4,
	.custom_input-number.ch-4 {
		--width: calc(4ch + 2 * var(--padding-right) + 2px);
	}
	.custom_input-text.ch-5,
	.custom_input-number.ch-5 {
		--width: calc(5ch + 2 * var(--padding-right) + 2px);
	}
}

/* TYPE = SEARCH  */
@layer components {
	.custom_input-search::-webkit-search-decoration,
	.custom_input-search::-webkit-search-cancel-button,
	.custom_input-search::-webkit-search-results-button,
	.custom_input-search::-webkit-search-results-decoration {
		display: none;
	}

	.custom_input-search {
		--padding-right: 2em;
		--width: 100%;
	}
}

/* TYPE = NUMBER  */
@layer components {
	.custom_input-number {
		--padding-right: 0.375rem;
		--padding-left: 0rem;
		--line-height: 1.625;
		--text-align: right;
	}

	.custom_input-number::-webkit-outer-spin-button,
	.custom_input-number::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	.custom_input-number[type='number'] {
		-moz-appearance: textfield;
	}
}

/* TYPE = CHECKBOX | RADIO */
@layer components {
	.custom_input-checkbox,
	.custom_input-radio {
		cursor: pointer;
		color: var(--color, var(--clr-primary));
		/* box-shadow: var(--shadow-inner), inset 0 -0.5px 1px 0 #00000025; */
		border: 1px solid var(--clr-border-dark);
	}

	.custom_input-checkbox:hover,
	.custom_input-radio:hover {
		border: 1px solid var(--clr-primary);
	}

	label:hover .custom_input-checkbox,
	label:hover .custom_input-radio {
		border: 1px solid var(--clr-primary);
	}

	.custom_input-checkbox:focus,
	.custom_input-radio:focus {
		box-shadow: 0 0 0 1px white, 0 0 0 3px var(--clr-primary);
	}
}

/* TYPE = CHECKBOX */
@layer components {
	.custom_input-checkbox {
		border-radius: 4px;
	}
}

/* TYPE = FILE */
@layer components {
	.custom_input-file {
	}
}
