.outerDiv {
	@apply flex items-center gap-6;
}

.left {
	@apply flex-grow flex flex-col gap-1.5;
}

.left h4 {
	font-size: 1.0625rem;
}

.link,
.stats,
.value,
.name {
	@apply flex items-center;
}

.stats .grid {
	font-size: 0.9375rem;
	@apply gap-x-2 whitespace-nowrap;
}

.stats .value {
	@apply gap-3 font-semibold;
}

.stats .name {
	@apply opacity-90;
}

.stats .value::before {
	content: '';
	width: 5px;
	height: 5px;
	@apply rounded bg-dark1 border border-dark1 opacity-80;
}
