@layer utilities {
	.row {
		display: flex;
		width: 100%;
	}

	.col-6 {
		display: flex;
		flex-basis: 50%;
		width: 50%;
	}

	.col-4 {
		display: flex;
		flex-basis: calc(100% / 3);
		width: calc(100% / 3);
	}

	.col-8 {
		display: flex;
		flex-basis: calc(100% / 3 * 2);
		width: calc(100% / 3 * 2);
	}
}
