@layer components {
	.main_layout_container {
		@apply flex h-full w-full max-w-full overflow-auto;
		max-height: calc(100vh - var(--navbar-height));
	}

	.main_layout_container > * {
		@apply flex-shrink-0 h-full max-h-full;
	}

	.main_layout_container-center {
		@apply flex flex-shrink flex-grow flex-col z-0 overflow-hidden;
	}

	.main_layout_container-top,
	.main_layout_container-bottom,
	.main_layout_container-left,
	.main_layout_container-right {
		@apply flex-shrink-0;
		box-shadow: 0 0 6px 0 #c8c8c8;
		z-index: 11;
	}

	.main_layout_container-top,
	.main_layout_container-bottom {
		background: #f8f8f8;
	}

	.main_layout_container-left,
	.main_layout_container-right {
		@apply bg-white;
	}

	.main_layout_container-content {
		@apply relative flex-grow overflow-auto;
	}
}
