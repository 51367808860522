.filter_button {
	position: relative;
	padding: 6px 14px !important;
}

.filter_button::after {
	content: '';
	position: absolute;
	bottom: -1px;
	height: 2px;
	left: 0;
	right: 0;
	background: var(--clr-primary);
	opacity: 0;
	transition: opacity var(--animation-duration);
}

.filter_button:hover::after {
	opacity: 0.4;
}

.active_filter_button {
	position: relative;
	color: var(--clr-primary-darker);
}

.active_filter_button::after {
	opacity: 1;
}

.header {
	position: sticky;
	top: -45px;
	background: var(--clr-light3);
	z-index: 1;
}

.scrolled_header {
	box-shadow: 0 0 12px 13px var(--clr-light3);
}
