.tag-dropdown {
}

.tag-dropdown > .tag-dropdown-item {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
}
.tag-dropdown > .tag-dropdown-item:hover {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  background: rgba(60, 171, 196, 0.5);
}
