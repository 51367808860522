@layer components {
	.zoom_and_span_target {
		--target-scale: 1;
		--target-origin-x: 0;
		--target-origin-y: 0;
		transform: scale(var(--target-scale));
		transform-origin: var(--target-origin-x) var(--target-origin-y);
	}

	.zoom_and_span_root > div:nth-child(2) {
		height: 9px !important;
		border-radius: 4px !important;
	}
	.zoom_and_span_root > div:nth-child(3) {
		width: 9px !important;
		border-radius: 4px !important;
	}
}
