@layer utilities {
	.rad-2 {
		border-radius: 2px;
	}
	.rad-3 {
		border-radius: 3px;
	}
	.rad-4 {
		border-radius: 4px;
	}
	.rad-5 {
		border-radius: 5px;
	}
	.rad-full {
		border-radius: 9999px;
	}
}
