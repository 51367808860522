@layer components {
	.project_card-active_module {
		@apply bg-light1;
		font-family: Roboto;
		border: 1px solid #dadada;
		padding: 1px 12px 0 12px;
		border-radius: 50px;
		cursor: default;
	}
}
