.svg {
	overflow: visible;
	stroke: currentColor;
}

.danger {
	stroke: var(--clr-danger);
}

.success {
	stroke: var(--clr-success);
}

.primary {
	stroke: var(--clr-primary);
}

.none {
	stroke: white;
}

.none_dark {
	stroke: var(--clr-dark1);
	opacity: 0.8;
}
