.logo {
	@apply relative
		bg-dark1
		rounded
		shadow-md;

	width: 240px;
	padding-top: 100%;
}

.logo svg {
	@apply absolute inset-0 w-full h-full;
}
