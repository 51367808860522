@layer components {
	.single_project_page-tab {
		@apply flex items-center text-default relative;
		padding: 0.5rem 1.5rem;
		transform: translateY(1px);
		user-select: none;
		height: 40px;
		position: relative;
	}

	.single_project_page-tab::after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0px;
		height: 2px;
		background: transparent;
		transition: background 150ms;
	}

	.single_project_page-tab:hover {
		@apply text-default;
	}

	.single_project_page-tab:hover::after {
		@apply bg-primary bg-opacity-40;
	}

	.single_project_page-tab.active {
		opacity: 1;
	}

	.single_project_page-tab.active p {
		@apply font-bolder;
	}

	.single_project_page-tab.active::after {
		@apply bg-primary bg-opacity-100;
	}

	.single_project_page-tab.active svg {
		/* @apply text-primary-darker; */
	}

	.single_project_page-active_module {
		@apply shadow-inner;
		font-family: Roboto;
		padding: 2px 8px;
		border-radius: 50px;
		line-height: initial;
		cursor: default;
		margin-left: 6px;
		z-index: -1;
	}
}
