.leftResizable:hover,
.leftResizable:active {
  border-left: 1px solid #00000040;
}

.leftResizable:hover::before,
.leftResizable:active::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 3px;
  height: 50px;
  width: 1px;
  transform: translateY(-50%);
  background: #00000040;
}
.bottom:hover,
.bottom:active {
  border-bottom: 1px solid #00000040;
}

.bottom:hover::before,
.bottom:active::before {
  content: "";
  position: absolute;
  bottom: 10;
  left: 50%;
  height: 1px;
  width: 50px;
  transform: translateX(-50%);
  background: #00000040;
}
.top:hover,
.top:active {
  border-top: 1px solid #00000040;
}

.top:hover::before,
.top:active::before {
  content: "";
  position: absolute;
  top: 10;
  left: 50%;
  height: 1px;
  width: 50px;
  transform: translateX(-50%);
  background: #00000040;
}
