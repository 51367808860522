.div {
	font-family: Roboto, 'Open Sans';
	position: relative;
	background: var(--clr-light1);
	border-radius: 3px;
	box-shadow: 0 6px 10px -5px #00000030, 0 0 10px -1px #00000005;
	height: clamp(400px, 58vh, 600px);
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.h3 {
	position: absolute;
	top: 27%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-weight: 500;
	opacity: 0.4;
	font-size: 1.5rem;
	margin: 0;
}

.a {
	border-radius: 2px;
	font-size: 1.3rem;
	padding: 0.25em 1.3em 0.25em 1.3em;
}
