.left,
.right {
	height: 100%;
	overflow: visible;
}

/* WRAPPER */
.left > div,
.right > div,
.wrapper {
	display: flex;
	position: relative;
	flex-direction: column;
	height: 100%;
	overflow: visible;
	width: var(--sidebar-width);
}

.left.collapse > div,
.right.collapse > div,
.left.collapse .wrapper,
.right.collapse .wrapper {
	display: flex;
	align-items: center;
	width: 50px;
	padding: 12px 0;
}

/* BUTTON */
.toggle_button {
	position: absolute;
	bottom: 0.75rem;
	opacity: 0.7;
}

.left .toggle_button {
	right: 0.5rem;
}

.left.collapse .toggle_button {
	transform: rotate(180deg);
}

.right .toggle_button {
	left: 0.5rem;
	transform: rotate(180deg);
}

.right.collapse .toggle_button {
	transform: rotate(0);
}

/* BUTTON COLLAPSED */
.collapse .toggle_button {
	position: static;
	margin-top: auto;
}
